import { Configuration, LogLevel } from "@azure/msal-browser";
// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

interface DashboardConfiguration {
  debug: boolean;
  msalConfig: Configuration;
  rootAPIUrl: string;
  rootAPIList: RootAPIList;
}

interface RootAPIList {
  roles: string;
  employeeSync: string;
  employeeLookup: string;
  employeeUpdateDate: string;
  integrationStatus: string;
  dailyReports: string;
  storeInfoSource: string; // a list with store names and basic info
  store411NetworkInfo: string;
  employeeSyncErrors: string;
  createTicket: string;
  storeWANStatus: string;
  deviceSummary: string;
  deviceStatus: string;
  storePeople: string;
  storeDailyHoursOfOpr: string;
  cardFree: string;
  store411NetworkEdit: string;
  cardFreeInfo: string;
  store411Aggregate: string;
  homeRefresh: string;
  productCatalog: string;
  productCatalogRefresh: string;
  devDashboard: string;
  scopes: string[];
}

export let config: DashboardConfiguration = {
  debug: false,
  // Config object to be passed to Msal on creation
  msalConfig: {
    auth: {
      clientId: "",
      authority: "",
      redirectUri: "", // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
      postLogoutRedirectUri: "", // Indicates the page to navigate after logout.
      clientCapabilities: ["CP1"], // this lets the resource owner know that this client is capable of handling claims challenge.
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: isIE || isEdge || isFirefox,
    },
    system: {
      // allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
          if (containsPii) {
            return;
          }
          // switch (level) {
          //     case LogLevel.Error:
          //         console.error(message);
          //         return;
          //     case LogLevel.Info:
          //         console.info(message);
          //         return;
          //     case LogLevel.Verbose:
          //         console.debug(message);
          //         return;
          //     case LogLevel.Warning:
          //         console.warn(message);
          //         return;
          //     default:
          //         return;
          // }
        },
      },
    },
  },
  rootAPIUrl: "",
  /**
   * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
   */
  rootAPIList: {
    roles: "",
    employeeSync: "",
    employeeLookup: "",
    employeeUpdateDate: "",
    integrationStatus: "",
    employeeSyncErrors: "",
    storeInfoSource: "",
    store411NetworkInfo: "",
    createTicket: "",
    storeWANStatus: "",
    deviceSummary: "",
    deviceStatus: "",
    dailyReports: "",
    storePeople: "",
    storeDailyHoursOfOpr: "",
    cardFree: "",
    store411NetworkEdit: "",
    cardFreeInfo: "",
    store411Aggregate: "",
    homeRefresh: "",
    productCatalog: "",
    productCatalogRefresh: "",
    devDashboard: "",
    scopes: [],
  },
};

let devUrl: string = "kind-beach-0080cc30f.5.azurestaticapps.net";
let devDomain: string = "diablodash-test.torchys.com";
let prodDomain: string = "diablodash.torchys.com";
let prodUrl: string = "icy-coast-0aa161e0f.5.azurestaticapps.net";
let releaseUrl: string = "calm-water-0f31d500f.5.azurestaticapps.net";

if (
  window.location.hostname === devUrl ||
  window.location.hostname === devDomain
) {
  config.rootAPIUrl = "https://func-torchysdashboard.azurewebsites.net";
  config.rootAPIList = {
    roles:
      "/api/user/roles?code=JxqdjyH0Gw080DgSTfhTo9ZM-QpBykphLk24dqZi2G2QAzFuECU8ug==",
    employeeSync:
      "/api/EmployeeSync/{id}?code=nnfdA4--YKvIJMvGsXKT1oZSPMG4vtI3Z6nuLTIEIdovAzFuDl8vJw==",
    employeeLookup:
      "/api/Employees/{id}?code=3qJ64Lv_dJu4XdNhmi6XpA3cUd_cQvQuar580ybq4hs6AzFu3SaurQ==",
    employeeUpdateDate:
      "/api/jobRates?code=b6q-pyIU9mdpQXyohLx1uhs-O5naOu9r4R8UHpB4F-R_AzFuEJNqLw==",
    integrationStatus:
      "/api/IntegrationStatus/{name}/{date}?code=ChwU2UopIWXYe6XkZvxipCJAXEcDncZ1UaLQeeF7GDf3AzFuc3biRg==",
    employeeSyncErrors:
      "/api/ErrorReportHttpFunc?startDate={startDate}&endDate={endDate}&reportName=EmployeeSync&code=Mz3lIGmLMmdE6tTkDQ63l3GrT6u-p9dsogUyhn4tXq0HAzFuxbDbrw==",
    storeInfoSource:
      "/api/stores?code=oy05AJbKVoiJpyoPWp11CMfFiykufu-d65wULZi34tT_AzFuSJPDCQ==",
    store411NetworkInfo:
      "/api/stores/network?code=iLG0r6eS46MNG2YWrZdgO6JNpKzuLgVruCJmupAjc7pCAzFu7Q2qdA==",
    createTicket:
      "/api/createTicket?code=qbLzTQxFEflirrluofUEAWR-erHuRgpncOqoL6rGHAlDAzFuq7nREg==",
    storeWANStatus:
      "/api/stores/wanstatus?code=o_g4EiDFrnlVAC_5siggMun_IBiCQuhcOZqTq08RthXNAzFuxLnUTg==",
    deviceSummary:
      "/api/stores/devicesummary?code=KTTVKHlrkLZavNydDal9WcHdGtGqJjIKv2JqiitaxNRQAzFu9_rzQA==",
    deviceStatus:
      "/api/devicestatus?code=69ddD3wuG6Pw7SULHRl9JoyXXmgTjwFp7jZzmSgh1Y9qAzFuuMTFmA==",
    dailyReports:
      "/api/DailyReporter/{name}/{date}?code=DAHxUo4nvoX38ig4MyNoEPdwzZksttWdDmsazCnEp8hZAzFuFPCIfA==",
    storePeople:
      "/api/stores/management?code=hQCOSx-ha0hB2jODkUkS1L3g9N7N3capqwZOK9KRhZXnAzFuFPymAw==",
    cardFree:
      "/api/stores/cardfreestatus?code=0xKqwHWfEkdpXhHZfcXFv5R_m4HF2LjBrvXD_TUoALgEAzFuzW7SYQ==",
    cardFreeInfo:
      "/api/stores/cardfreeinfo?code=NVqG6T_0B456lhQM7V1ooAg77TbSE2NLWWwRbAeEVjwBAzFuBUUfBw==",
    storeDailyHoursOfOpr:
      "/api/stores/cardfreehoo?code=imx2Cn4cHtgxfBTk-nj9LIHy-MVaQgKfYNN0xWGK_T6eAzFuE3zFRQ==",
    store411NetworkEdit:
      "/api/wanAddresses/?code=wRQaToPvVoc-kKC4XlpcOW8KZE_ik6rBtFNquwht1P6xAzFutfhQWg==",
    store411Aggregate:
      "/api/storeaggregates?code=5ZIG5nTE2u8B8Lnh076qmAS8lQe4X_1qy9WTgIWjA9a1AzFuShsB1A%3D%3D",
    homeRefresh:
      "/api/manualrefresh?code=dJsIhaZmQchUNxcDnqn4YCWpMQfKRnn_WgDYxTuSnE5oAzFu49yLpw==",
      productCatalog: "/api/product/catalog?code=3A5dD2QSm-aD6Ep5coYM4APqx_XMlIGy3ETylardHgiTAzFuuk8yPw==",
      productCatalogRefresh: "/api/product/catalog/refresh?code=0aApGiaVlQ3XH6emEo_Iur0lJcNRC9d2wFV-lYaZg8UNAzFuY-JZuw==",
    devDashboard:
      "/api/devdashboard?code=_VXHcOVEfIa2cC2OUgf1LumCYjTa0rJvhSh2Hea90Lm6AzFueR77Og==&startDate={startDate}&endDate={endDate}",
    scopes: ["api://e088f5fb-ac21-440b-8494-85df1214f99d/roles", "User.Read"],
    //dev
  };
} else if (
  window.location.hostname === prodUrl ||
  window.location.hostname === prodDomain
) {
  config.rootAPIUrl = "https://func-torchysdashboard-prod.azurewebsites.net";
  config.rootAPIList = {
    roles:
      "/api/user/roles?code=jrXUkN8kJkHYrGnuL0ebQxdb_O-0bEBrBCpfRqH9z2D0AzFuFpNuTw==",
    employeeSync:
      "/api/EmployeeSync/{id}?code=uxevwJzvIL8uH1AmneLDbK4R_05pVGT0w4oW__dzfi56AzFufghBSA==",
    employeeLookup:
      "/api/Employees/{id}?code=gqlkxhD7Td0PQ4C4D6n8dMSBTTxkH6fLtleW66ohMNn2AzFurnTryA==",
    employeeUpdateDate:
      "/api/jobRates?code=1pUAofw1_LdHVECWykVTG-cg8ORibucPldVNdK5AtDX5AzFu2F6MOw==",
    integrationStatus:
      "/api/IntegrationStatus/{name}/{date}?code=xKbQ8SkypAlLWYxQ3_4mrnly5sIZH2c6alE_Y7mh5I_SAzFuFXct3Q==",
    employeeSyncErrors:
      "/api/ErrorReportHttpFunc?startDate={startDate}&endDate={endDate}&reportName=EmployeeSync&code=pScEFeqaL82NFvictZPlIRl9StlMPceDIn7c0yGcxFYuAzFuNs7GEQ==",
    createTicket:
      "/api/createTicket?code=nlSocODss0N7-fLh4fMt82vAx_zgJbGPkvyzZXP8pbYMAzFuH25r3Q%3D%3D",
    storeInfoSource:
      "/api/stores?code=i42U3oRDPTH27ehN4CxsAICPg9iILXCeVnAoNfs5_I61AzFuYATKkw%3D%3D",
    store411NetworkInfo:
      "/api/stores/network?code=3TBLW0Wty7-oVFa8yCpQiRBJw5uxov-rcRdUGwEXJct1AzFulzm_Qg==",
    storeWANStatus:
      "/api/stores/wanstatus?code=IADE7U5MAOqQrs9bsvYu8JVhzdF3IxvsceAMDH6FIp7UAzFuW2KC4A%3D%3D",
    deviceSummary:
      "/api/stores/devicesummary?code=XeRQlPo5XKqjCB9oATQ1nw8zjKbm4TtedjxaACo6w0bJAzFul9We8Q==",
    deviceStatus:
      "/api/devicestatus?code=yx_cWqAp9FbRnn6loRX3ObiW8qiKAalIhU3oVfGIE3BRAzFudxGqzA==",
    dailyReports:
      "/api/DailyReporter/{name}/{date}?code=iCvL-fGteXdxxsaLVcgq2gkiCfu8dn_PpDst19Q7qYgQAzFudsydZQ%3D%3D",
    storePeople:
      "/api/stores/management?code=n1K8w3q3VZJRqhwldWx2GIxWje8cpt9K-kC8fhCyyVIBAzFu-xA-xQ==",
    cardFree:
      "/api/stores/cardfreestatus?code=01xyz-xkZecojweK1X-3DPaRzi2I1K4x6X0Y2l1Gxn5YAzFunsFB1w%3D%3D",
    store411NetworkEdit:
      "/api/wanAddresses/?code=0iQGUXcuHWG7WdNiAfcg-st5AQBfpkOPPB71tpFL9JnhAzFuyE95kg==",
    cardFreeInfo:
      "/api/stores/cardfreeinfo?code=16RQJrWBMf0UV88__iESJNa1FL3t_cBjcnrSqCQB_n9mAzFuVUuBlg==",
    storeDailyHoursOfOpr:
      "/api/stores/cardfreehoo?code=EwHJ_KhHH1wYSduQw6YwmZ-Zur-W7gNX6u66fL2WXzhEAzFu72EWYw==",
    store411Aggregate:
      "/api/storeaggregates?code=TqinRW581PRZgjkXsSa0NgNd9M8VS_Zlli4trXB7MHc7AzFuXS1u3w==",
    homeRefresh:
      "/api/manualrefresh?code=ccM5WT2jR6AIjaixm6MTVK9BixgSMXOzVFy0u6pGqtnzAzFuSDoWyQ==",
    productCatalog: "/api/product/catalog?code=ytfqq1SKjjtYjFrm7tWJb3g85eEUanXt9nqdfDw-Iaz2AzFuNBrsRw==",
      productCatalogRefresh: "/api/product/catalog/refresh?code=BFNWXXPR0ZAG48RsX2p4UqLdU-OlkNuweRApOMzRncV_AzFuUhcRpA==",
      devDashboard:
          "/api/devdashboard?code=ihuloBhY9ZHhj2e_AYxlw4TOlh98D4TGiOAFNAMmuRg9AzFukmgGiQ==&startDate={startDate}&endDate={endDate}",
    scopes: ["api://e088f5fb-ac21-440b-8494-85df1214f99d/roles", "User.Read"],
    //prod
  };
} else if (window.location.hostname === releaseUrl) {
  config.rootAPIUrl = "https://func-torchysdashboard-release.azurewebsites.net";
  config.rootAPIList = {
    roles:
      "/api/user/roles?code=imFalb_KBwEoOfbsXE4UCQhO1h01jrzIKCt2vEWa_r5mAzFuxO7KRg%3D%3D",
    employeeSync:
      "/api/EmployeeSync/{id}?code=2RIS4Avu6j53kXg9qDiV2bpjbHZX-lC6D65CI-iBAq-hAzFu1vXfhQ%3D%3D",
    employeeLookup:
      "/api/Employees/{id}?code=ywuWsGxadzKwMzFbJdy332LqYvv6dg520GAfoZ10CFbZAzFuXzgGqQ%3D%3D",
    employeeUpdateDate:
      "/api/jobRates?code=PqtAEJ2jXEw0J9XR5MpGFf-hELtf47MGu3w847CxrsMnAzFuwjuLlg%3D%3D",
    integrationStatus:
      "/api/IntegrationStatus/{name}/{date}?code=bnaw8m2Fu54qYmrCcnwn1xzSR4Za1sL4cPcK-1LSfLM-AzFunrtc2A%3D%3D",
    employeeSyncErrors:
      "/api/ErrorReportHttpFunc?startDate={startDate}&endDate={endDate}&reportName=EmployeeSync&code=6q2hd3G0XThjYFVZh7CMk3zCsJJi0EYRNJ7yguqzSLpEAzFuz4Jtaw%3D%3D",
    createTicket:
      "/api/createTicket?code=7G8LDXXALI1k08foZyu4TgAOoTQuxVIwulmRB0beqSK_AzFuvmSCkQ%3D%3D",
    storeInfoSource:
      "/api/stores?code=AHJLsu8ri_JrcAbA97u7Vif19I0qx7AbEMn-yj9KeahUAzFunJh3tg%3D%3D",
    store411NetworkInfo:
      "/api/stores/network?code=NHpPawDilklOVQGIA8nAyF5SHsVcdP8oqnKxcR76oe2rAzFuceMDCA%3D%3D",
    storeWANStatus:
      "/api/stores/wanstatus?code=qVePflNKuFdwIgmy9SjXVcPAJOy6Jl7aSStiDEYHBqy0AzFunEPwUA%3D%3D",
    deviceSummary:
      "/api/stores/devicesummary?code=p2V9YCfCs0mJ8-23X2S8RvJExoIq58_XVl_3Gyg5FC2GAzFuT49DOA==",
    deviceStatus:
      "/api/devicestatus?code=wDTx9oILz26hBDVU1bzDZResxiRu22DcFvkMXQOYIEkdAzFu4IZeTQ==",
    dailyReports:
      "/api/DailyReporter/{name}/{date}?code=jR7_hEY0xMlAm3VNGI_AbHmZj3VWJczHA0ugLGf5M2dHAzFuV7bJWw%3D%3D",
    storePeople:
      "/api/stores/management?code=lcoFdq_lpGjRoIQoJyCIHIRqLlUJagG5E9qGKIwFGjQzAzFu9sPKIQ%3D%3D",
    cardFree:
      "/api/stores/cardfreestatus?code=S4r4ZOKNg2jd8SQ3c9JsOjHHlmqVy5_oGbAdttetIGK0AzFuLF6IDg%3D%3D",
    storeDailyHoursOfOpr:
      "/api/stores/cardfreehoo?code=B-uCnMLxNfuHNrHnHgEsiWBAjqV09udixzKioKegFVrgAzFuYnZ2Hw==",
    store411NetworkEdit:
      "/api/wanAddresses/?code=1Vqtyar-e4t53nGrnxd9x6mA8n9UiIAl5J0F3vko87z3AzFu70dV-A==",
    cardFreeInfo:
      "/api/stores/cardfreeinfo?code=DEEZRfPYAu02ir5NDfz9lzQ8TLUPqDos0W_gcvI3oQuoAzFu-SNIWw==",
    store411Aggregate:
      "/api/storeaggregates?code=0gm9KyGd6jJRAK0AHTJtJXPNqxqMwjkAdM3Kh8Hjy40aAzFu0Luy9g==",
    homeRefresh:
      "/api/manualrefresh?code=V9fLT0kWc_WKyt_QDg-YaM2l19TmIOBCtbvmFL_F2kTxAzFu9x5qYg==",
    productCatalog: "/api/product/catalog?code=PAXGN03rwgovJm95hhjwMbjFf8XquGuB-taY6-90wHRpAzFu5IbimQ==",
      productCatalogRefresh: "/api/product/catalog/refresh?code=m48mrKRv1GszMZzGtwK2g0hZ5uxSAyuCWnlJhuMPkI8BAzFu_hon-Q==",
      devDashboard:
          "/api/devdashboard?code=2x9xE-eNEgrOJe_CtEaL6drVpQjD_9RSA0UZtlyr6Q6PAzFubuwfrw==&startDate={startDate}&endDate={endDate}",
    scopes: ["api://e088f5fb-ac21-440b-8494-85df1214f99d/roles", "User.Read"],
    //release
  };
} else {
  config.rootAPIUrl = "http://localhost:7071";
  config.rootAPIList = {
    roles: "/api/user/roles",
    employeeSync: "/api/EmployeeSync/{id}",
    employeeLookup: "/api/Employees/{id}",
    employeeUpdateDate: "/api/jobRates",
    integrationStatus: "/api/IntegrationStatus/{name}/{date}",
    employeeSyncErrors:
      "/api/ErrorReportHttpFunc?startDate={startDate}&endDate={endDate}&reportName=EmployeeSync",
    storeInfoSource: "/api/stores",
    createTicket: "/api/createTicket",
    storeWANStatus: "/api/stores/wanstatus",
    deviceSummary: "/api/stores/devicesummary",
    deviceStatus: "/api/devicestatus",
    store411NetworkInfo: "/api/stores/network",
    dailyReports: "/api/DailyReporter/{name}/{date}",
    storePeople: "/api/stores/management",
    cardFree: "/api/stores/cardfreestatus",
    storeDailyHoursOfOpr: "/api/stores/cardfreehoo",
    store411NetworkEdit: "/api/wanAddresses",
    cardFreeInfo: "/api/stores/cardfreeinfo",
    store411Aggregate: "/api/storeaggregates",
    homeRefresh: "/api/manualrefresh",
    productCatalog: "/api/product/catalog",
    productCatalogRefresh: "/api/product/catalog/refresh",
    devDashboard: "/api/devdashboard?startDate={startDate}&endDate={endDate}",
    scopes: ["api://e088f5fb-ac21-440b-8494-85df1214f99d/roles", "User.Read"], // backends api scope.
    //local
  };
  //********************************************* */
  // config.rootAPIUrl = "https://func-torchysdashboard.azurewebsites.net";
  // config.rootAPIList = {
  //   roles:
  //     "/api/user/roles?code=JxqdjyH0Gw080DgSTfhTo9ZM-QpBykphLk24dqZi2G2QAzFuECU8ug==",
  //   employeeSync:
  //     "/api/EmployeeSync/{id}?code=nnfdA4--YKvIJMvGsXKT1oZSPMG4vtI3Z6nuLTIEIdovAzFuDl8vJw==",
  //   employeeLookup:
  //     "/api/Employees/{id}?code=3qJ64Lv_dJu4XdNhmi6XpA3cUd_cQvQuar580ybq4hs6AzFu3SaurQ==",
  //   employeeUpdateDate:
  //     "/api/jobRates?code=b6q-pyIU9mdpQXyohLx1uhs-O5naOu9r4R8UHpB4F-R_AzFuEJNqLw==",
  //   integrationStatus:
  //     "/api/IntegrationStatus/{name}/{date}?code=ChwU2UopIWXYe6XkZvxipCJAXEcDncZ1UaLQeeF7GDf3AzFuc3biRg==",
  //   employeeSyncErrors:
  //     "/api/ErrorReportHttpFunc?startDate={startDate}&endDate={endDate}&reportName=EmployeeSync&code=Mz3lIGmLMmdE6tTkDQ63l3GrT6u-p9dsogUyhn4tXq0HAzFuxbDbrw==",
  //   storeInfoSource:
  //     "/api/stores?code=oy05AJbKVoiJpyoPWp11CMfFiykufu-d65wULZi34tT_AzFuSJPDCQ==",
  //   store411NetworkInfo:
  //     "/api/stores/network?code=iLG0r6eS46MNG2YWrZdgO6JNpKzuLgVruCJmupAjc7pCAzFu7Q2qdA==",
  //   createTicket:
  //     "/api/createTicket?code=qbLzTQxFEflirrluofUEAWR-erHuRgpncOqoL6rGHAlDAzFuq7nREg==",
  //   storeWANStatus:
  //     "/api/stores/wanstatus?code=o_g4EiDFrnlVAC_5siggMun_IBiCQuhcOZqTq08RthXNAzFuxLnUTg==",
  //   dailyReports:
  //     "/api/DailyReporter/{name}/{date}?code=DAHxUo4nvoX38ig4MyNoEPdwzZksttWdDmsazCnEp8hZAzFuFPCIfA==",
  //   storePeople:
  //     "/api/stores/management?code=hQCOSx-ha0hB2jODkUkS1L3g9N7N3capqwZOK9KRhZXnAzFuFPymAw==",
  //   cardFree:
  //     "/api/stores/cardfreestatus?code=0xKqwHWfEkdpXhHZfcXFv5R_m4HF2LjBrvXD_TUoALgEAzFuzW7SYQ==",
  //   storeDailyHoursOfOpr:
  //     "/api/stores/cardfreehoo?code=imx2Cn4cHtgxfBTk-nj9LIHy-MVaQgKfYNN0xWGK_T6eAzFuE3zFRQ==",
  //   cardFreeInfo:
  //     "/api/stores/cardfreeinfo?code=NVqG6T_0B456lhQM7V1ooAg77TbSE2NLWWwRbAeEVjwBAzFuBUUfBw==",
  //   store411NetworkEdit:
  //     "/api/wanAddresses/?code=wRQaToPvVoc-kKC4XlpcOW8KZE_ik6rBtFNquwht1P6xAzFutfhQWg==",
  //   store411Aggregate:
  //     "/api/storeaggregates?code=5ZIG5nTE2u8B8Lnh076qmAS8lQe4X_1qy9WTgIWjA9a1AzFuShsB1A%3D%3D",
  //   deviceSummary:
  //     "/api/stores/devicesummary?code=KTTVKHlrkLZavNydDal9WcHdGtGqJjIKv2JqiitaxNRQAzFu9_rzQA==",
  //     deviceStatus:"/api/devicestatus?code=69ddD3wuG6Pw7SULHRl9JoyXXmgTjwFp7jZzmSgh1Y9qAzFuuMTFmA==",
  //   homeRefresh:
  //     "/api/manualrefresh?code=dJsIhaZmQchUNxcDnqn4YCWpMQfKRnn_WgDYxTuSnE5oAzFu49yLpw==",
    //productCatalog: "/api/product/catalog?code=3A5dD2QSm-aD6Ep5coYM4APqx_XMlIGy3ETylardHgiTAzFuuk8yPw==",
    //    productCatalogRefresh: "/api/product/catalog/refresh?code=0aApGiaVlQ3XH6emEo_Iur0lJcNRC9d2wFV-lYaZg8UNAzFuY-JZuw==",
    //        devDashboard:
    //"/api/devdashboard?code=_VXHcOVEfIa2cC2OUgf1LumCYjTa0rJvhSh2Hea90Lm6AzFueR77Og==&startDate={startDate}&endDate={endDate}",
  //   scopes: ["api://e088f5fb-ac21-440b-8494-85df1214f99d/roles", "User.Read"], // backends api scope.
  // };
  //********************************************* */
}

config.msalConfig.auth = {
  clientId: "0fe29f92-5c39-4deb-a9cb-9edefc95abfd", // fronend app reg new - 0fe29f92-5c39-4deb-a9cb-9edefc95abfd
  authority:
    "https://login.microsoftonline.com/d399117b-c79f-4544-82d0-ab17b7a15628/", // tenant id
  clientCapabilities: ["CP1"], // this lets the resource owner know that this client is capable of handling claims challenge.
};

if (
  window.location.hostname === prodUrl ||
  window.location.hostname === prodDomain ||
  window.location.hostname === devUrl ||
  window.location.hostname === devDomain ||
  window.location.hostname === releaseUrl
) {
  config.msalConfig.auth.redirectUri = `${window.location.protocol}//${window.location.hostname}`; // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
  config.msalConfig.auth.postLogoutRedirectUri = `${window.location.protocol}//${window.location.hostname}`; // Indicates the page to navigate after logout.
} else {
  //localhost
  config.msalConfig.auth.redirectUri = `${window.location.protocol}//${window.location.hostname}:3000`; // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
  config.msalConfig.auth.postLogoutRedirectUri = `${window.location.protocol}//${window.location.hostname}:3000`; // Indicates the page to navigate after logout.
}

console.log(config.msalConfig.auth);

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest: { scopes: string[]; resource: string } = {
  scopes: [...config.rootAPIList.scopes],
  resource: "https://management.core.windows.net/",
};

export const appRoles = {
  EmployeeLookup: "EmployeeSync.Lookup",
  EmployeeSyncErrors: "EmployeeSync.Manual",
  EmployeeEffectiveDateUpdate: "EmployeeSync.UpdateEffectiveDate",
  Integration: {
    ViewStatus: "Integrations.ViewStatus",
    ViewSyncErrorReport: "Integrations.ViewEmployeeSyncErrorsReport",
    CreateTickets: "Integrations.CreateTicket",
  },
  StoreWANInfo: "Store411.ViewWanInfo",
  StoreWANEdit: "Store411.EditWanStatus",
  HelpPage: "EmployeeSync.Manual",
  Reporting: "Reports.View",
  EmployeeSyncShowRates: "EmployeeSync.ShowRates",
  StoreInfo: {
    ViewPeople: "Store411.ViewPeople",
    ViewStores: "Store411.ViewStores",
    CardFreeHOO: "Store411.ViewHoursOfOperation",
    ViewBuilding: "Store411.ViewBuilding",
    ViewPOSTerminals: "Store411.ViewPOSTerminals",
  },
  Home: {
    CardFreeStatus: "Home.ViewCardFreeStatus",
    FirewallStatus: "Home.ViewWanStatus",
    ViewPOSStatus: "Home.ViewDeviceStatus",
    ExecuteManualRefresh: "Home.ExecuteManualRefresh",
  },
  ProductCatalog: "Product.ViewProductCatalog",
  DevDashboard: "DevDashboard.View",
};

export const pageNames = {
  helpPage: "Help Page",
  store411: "Store 411",
  integrations: "Integrations",
  reports: "Reports",
  intStatus: "Integrations Status",
  intEmployeeSync: "Emp Sync to POS",
  managePOSEmployee: "Manage POS Emp",
  store411Info: "Store 411 Info",
  store411InfoDetails: "Store 411 Info Details",
    productCatalog: "Product Catalog",
    devDashboard: "Dev Dashboard",
};
