import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Menu,
  MenuItem,
  Switch,
} from "@mui/material";
import {
  ChangeEvent,
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link } from "react-router-dom";
import { appRoles, config, pageNames } from "../../authConfig";
import useFetchMsal from "../../Common/UI/hook/useFetchMsal";
import useUserRoles from "../../Common/UI/hook/useUserRoles";
import { LoadingIndicator } from "../../Common/UI/components/Loading";
import { ApplicationLocationContext } from "../../Common/UI/utils/ApplicationState";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import ViewWeekIcon from "@mui/icons-material/ViewWeek";
import DownloadIcon from "@mui/icons-material/Download";
import dayjs from "dayjs";
import ManagerInfo from "./models/ManagerInfo";
import { StoreAggregate } from "./models/StoreAggregate";
import DeviceInfo from "./models/DeviceInfo";
import WANInfo from "./models/WanInfo";

export const Stores = (): ReactElement => {
  const [storesInfo, setStoresInfo] = useState<StoreAggregate[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [colDefs, setColDefs] = useState<ColDef[]>([]);

  const [menuAnchorElement, setMenuAnchorElement] =
    useState<HTMLElement | null>(null);
  const gridRef = useRef<AgGridReact>(null);

  const { setState } = useContext(ApplicationLocationContext);
  const execute = useFetchMsal();
  const roles = useUserRoles();

  useEffect(() => {
    const fetchStoresInfo = async () => {
      try {
        setLoading(true);

        const url = config.rootAPIList.store411Aggregate;
        const response = await execute("GET", `${config.rootAPIUrl}${url}`);

        deviceFlatResult(response);
        setStoresInfo(response);
      } catch (error: any) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    const deviceFlatResult = (stores: StoreAggregate[]) => {
      const CASHIER1 = "CASHIER1";
      const CASHIER2 = "CASHIER2";
      const CASHIER3 = "CASHIER3";
      const BAR1 = "BAR1";
      const BAR2 = "BAR2";
      const DRIVETHRU1 = "DRIVETHRU1";
      const TAKEOUT1 = "TAKEOUT1";
      const TAKEOUT2 = "TAKEOUT2";

      for (var j = 0; j < stores.length; j++) {
        stores[j].devices_flat = {
          CASHIER1: null,
          CASHIER2: null,
          CASHIER3: null,
          BAR1: null,
          BAR2: null,
          DRIVETHRU1: null,
          TAKEOUT1: null,
          TAKEOUT2: null,
        };
        const terminals = stores[j].devices || [];
        for (var i = 0; i < terminals.length; i++) {
          if (terminals[i].name.endsWith(CASHIER1)) {
            stores[j].devices_flat[CASHIER1] = terminals[i].status === "active";
          } else if (terminals[i].name.endsWith(CASHIER2)) {
            stores[j].devices_flat[CASHIER2] = terminals[i].status === "active";
          } else if (terminals[i].name.endsWith(CASHIER3)) {
            stores[j].devices_flat[CASHIER3] = terminals[i].status === "active";
          } else if (terminals[i].name.endsWith(BAR1)) {
            stores[j].devices_flat[BAR1] = terminals[i].status === "active";
          } else if (terminals[i].name.endsWith(BAR2)) {
            stores[j].devices_flat[BAR2] = terminals[i].status === "active";
          } else if (terminals[i].name.endsWith(DRIVETHRU1)) {
            stores[j].devices_flat[DRIVETHRU1] =
              terminals[i].status === "active";
          } else if (terminals[i].name.endsWith(TAKEOUT1)) {
            stores[j].devices_flat[TAKEOUT1] = terminals[i].status === "active";
          } else if (terminals[i].name.endsWith(TAKEOUT2)) {
            stores[j].devices_flat[TAKEOUT2] = terminals[i].status === "active";
          }
        }
      }
    };

    setState({
      path: [pageNames.store411],
    });

    fetchStoresInfo();
  }, []);

  useEffect(() => {
    if (storesInfo.length) {
      const rolesGridColsMap = new Map<string, ColDef[]>([
        [
          appRoles.StoreInfo.ViewStores,
          [
            {
              field: "basicInfo.number",
              headerName: "Number",
              headerClass: "tableHeader",
              filter: "agNumberColumnFilter",
              cellRenderer: ({ value }: ICellRendererParams) => (
                <Link to={`/store-411/${value}`} state={storesInfo}>
                  {value?.padStart(3, "0")}
                </Link>
              ),
              filterValueGetter: ({ data }) => Number(data.basicInfo.number),
              comparator(valueA, valueB) {
                return Number(valueA) - Number(valueB);
              },
            },
            {
              field: "basicInfo.name",
              headerName: "Name",
              headerClass: "tableHeader",
            },
            {
              field: "basicInfo.status",
              headerName: "Status",
              headerClass: "tableHeader",
            },
            {
              field: "basicInfo.phone",
              headerName: "Phone",
              headerClass: "tableHeader",
            },
            {
              field: "basicInfo.state",
              headerName: "State",
              headerClass: "tableHeader",
            },
            {
              field: "basicInfo.district",
              headerName: "District",
              headerClass: "tableHeader",
            },
            {
              field: "basicInfo.active",
              headerName: "Active",
              headerClass: "tableHeader",
              hide: true,
            },
            {
              field: "basicInfo.addressLine1",
              headerName: "Address Line 1",
              headerClass: "tableHeader",
              hide: true,
            },
            {
              field: "basicInfo.addressLine2",
              headerName: "Address Line 2",
              headerClass: "tableHeader",
              hide: true,
            },
            {
              field: "basicInfo.city",
              headerName: "City",
              headerClass: "tableHeader",
              hide: true,
            },
            {
              field: "basicInfo.postalCode",
              headerName: "Postal Code",
              headerClass: "tableHeader",
              hide: true,
            },
            {
              field: "basicInfo.region",
              headerName: "Region",
              headerClass: "tableHeader",
              hide: true,
            },
            {
              field: "basicInfo.openDate",
              headerName: "Open Date",
              headerClass: "tableHeader",
              filter: "agDateColumnFilter",
              hide: true,
              filterParams: {
                comparator: (filterDate: Date, cellValue: any) => {
                  const dateAsString = cellValue;

                  if (dateAsString == null) {
                    return 0;
                  }

                  const dateParts = dateAsString.split("/");
                  const year = Number(dateParts[2]);
                  const month = Number(dateParts[0]) - 1;
                  const day = Number(dateParts[1]);
                  const cellDate = new Date(year, month, day);

                  if (cellDate < filterDate) {
                    return -1;
                  } else if (cellDate > filterDate) {
                    return 1;
                  }
                  return 0;
                },
              },
              valueGetter: ({ data }) => {
                return dayjs(data.basicInfo?.openDate).isValid()
                  ? dayjs(data.basicInfo?.openDate).format("MM/DD/YYYY")
                  : "";
              },
            },
            {
              field: "basicInfo.alternateName",
              headerName: "Alternate Name",
              headerClass: "tableHeader",
              hide: true,
            },
            {
              field: "basicInfo.timeZone",
              headerName: "Time Zone",
              headerClass: "tableHeader",
              hide: true,
            },
            {
              field: "basicInfo.directPhoneNumber",
              headerName: "Direct Phone Number",
              headerClass: "tableHeader",
              hide: true,
            },
            {
              field: "basicInfo.dateClosed",
              headerName: "Date Closed",
              headerClass: "tableHeader",
              filter: "agDateColumnFilter",
              hide: true,
              filterParams: {
                comparator: (filterDate: Date, cellValue: any) => {
                  const dateAsString = cellValue;

                  if (dateAsString == null) {
                    return 0;
                  }

                  const dateParts = dateAsString.split("/");
                  const year = Number(dateParts[2]);
                  const month = Number(dateParts[0]) - 1;
                  const day = Number(dateParts[1]);
                  const cellDate = new Date(year, month, day);

                  if (cellDate < filterDate) {
                    return -1;
                  } else if (cellDate > filterDate) {
                    return 1;
                  }
                  return 0;
                },
              },
              valueGetter: ({ data }) => {
                return dayjs(data.basicInfo?.dateClosed).isValid()
                  ? dayjs(data.basicInfo?.dateClosed).format("MM/DD/YYYY")
                  : "";
              },
            },
          ],
        ],
        [
          appRoles.Home.CardFreeStatus,
          [
            {
              field: "email",
              headerName: "Email",
              headerClass: "tableHeader",
              hide: true,
            },
          ],
        ],
        [
          appRoles.StoreWANInfo,
          [
            {
              field: "wanInfo.location",
              headerName: "Location",
              headerClass: "tableHeader",
              hide: true,
              valueGetter: ({ data }) =>
                data.wanInfo?.find((x: WANInfo) => x.type === "Primary")
                  .location,
            },
            {
              field: "wanInfo.primary.wanipAddress",
              headerName: "Primary WAN IP Address",
              headerClass: "tableHeader",
              hide: true,
              valueGetter: ({ data }) =>
                data.wanInfo?.find((x: WANInfo) => x.type === "Primary")
                  ?.wanipAddress,
            },
            {
              field: "wanInfo.primary.physicalInterface",
              headerName: "Primary Physical Interface",
              headerClass: "tableHeader",
              hide: true,
              valueGetter: ({ data }) =>
                data.wanInfo?.find((x: WANInfo) => x.type === "Primary")
                  ?.physicalInterface,
            },
            {
              field: "wanInfo.primary.type",
              headerName: "Primary Type",
              headerClass: "tableHeader",
              hide: true,
              valueGetter: ({ data }) =>
                data.wanInfo?.find((x: WANInfo) => x.type === "Primary")?.type,
            },
            {
              field: "wanInfo.primary.billing",
              headerName: "Primary Billing",
              headerClass: "tableHeader",
              hide: true,
              valueGetter: ({ data }) =>
                data.wanInfo?.find((x: WANInfo) => x.type === "Primary")
                  ?.billing,
            },
            {
              field: "wanInfo.primary.isp",
              headerName: "Primary ISP",
              headerClass: "tableHeader",
              hide: true,
              valueGetter: ({ data }) =>
                data.wanInfo?.find((x: WANInfo) => x.type === "Primary")?.isp,
            },
            {
              field: "wanInfo.primary.supportName",
              headerName: "Primary Support Name",
              headerClass: "tableHeader",
              hide: true,
              valueGetter: ({ data }) =>
                data.wanInfo?.find((x: WANInfo) => x.type === "Primary")
                  ?.supportName,
            },
            {
              field: "wanInfo.primary.supportPhoneNumber",
              headerName: "Primary Support Phone Number",
              headerClass: "tableHeader",
              hide: true,
              valueGetter: ({ data }) =>
                data.wanInfo?.find((x: WANInfo) => x.type === "Primary")
                  ?.supportPhoneNumber,
            },
            {
              field: "wanInfo.primary.supportEmail",
              headerName: "Primary Support Email",
              headerClass: "tableHeader",
              hide: true,
              valueGetter: ({ data }) =>
                data.wanInfo?.find((x: WANInfo) => x.type === "Primary")
                  ?.supportEmail,
            },
            {
              field: "wanInfo.primary.staticIP",
              headerName: "Primary Static IP",
              headerClass: "tableHeader",
              hide: true,
              valueGetter: ({ data }) =>
                data.wanInfo?.find((x: WANInfo) => x.type === "Primary")
                  ?.staticIP,
            },
            {
              field: "wanInfo.primary.subnet",
              headerName: "Primary Subnet",
              headerClass: "tableHeader",
              hide: true,
              valueGetter: ({ data }) =>
                data.wanInfo?.find((x: WANInfo) => x.type === "Primary")
                  ?.subnet,
            },
            {
              field: "wanInfo.primary.gateway",
              headerName: "Primary Gateway",
              headerClass: "tableHeader",
              hide: true,
              valueGetter: ({ data }) =>
                data.wanInfo?.find((x: WANInfo) => x.type === "Primary")
                  ?.gateway,
            },
            {
              field: "wanInfo.secondary.wanipAddress",
              headerName: "Secondary WAN IP Address",
              headerClass: "tableHeader",
              hide: true,
              valueGetter: ({ data }) =>
                data.wanInfo?.find((x: WANInfo) => x.type === "Secondary")
                  ?.wanipAddress,
            },
            {
              field: "wanInfo.secondary.physicalInterface",
              headerName: "Secondary Physical Interface",
              headerClass: "tableHeader",
              hide: true,
              valueGetter: ({ data }) =>
                data.wanInfo?.find((x: WANInfo) => x.type === "Secondary")
                  ?.physicalInterface,
            },
            {
              field: "wanInfo.secondary.type",
              headerName: "Secondary Type",
              headerClass: "tableHeader",
              hide: true,
              valueGetter: ({ data }) =>
                data.wanInfo?.find((x: WANInfo) => x.type === "Secondary")
                  ?.type,
            },
            {
              field: "wanInfo.secondary.billing",
              headerName: "Secondary Billing",
              headerClass: "tableHeader",
              hide: true,
              valueGetter: ({ data }) =>
                data.wanInfo?.find((x: WANInfo) => x.type === "Secondary")
                  ?.billing,
            },
            {
              field: "wanInfo.secondary.isp",
              headerName: "Secondary ISP",
              headerClass: "tableHeader",
              hide: true,
              valueGetter: ({ data }) =>
                data.wanInfo?.find((x: WANInfo) => x.type === "Secondary")?.isp,
            },
            {
              field: "wanInfo.secondary.supportName",
              headerName: "Secondary Support Name",
              headerClass: "tableHeader",
              hide: true,
              valueGetter: ({ data }) =>
                data.wanInfo?.find((x: WANInfo) => x.type === "Secondary")
                  ?.supportName,
            },
            {
              field: "wanInfo.secondary.supportPhoneNumber",
              headerName: "Secondary Support Phone Number",
              headerClass: "tableHeader",
              hide: true,
              valueGetter: ({ data }) =>
                data.wanInfo?.find((x: WANInfo) => x.type === "Secondary")
                  ?.supportPhoneNumber,
            },
            {
              field: "wanInfo.secondary.supportEmail",
              headerName: "Secondary Support Email",
              headerClass: "tableHeader",
              hide: true,
              valueGetter: ({ data }) =>
                data.wanInfo?.find((x: WANInfo) => x.type === "Secondary")
                  ?.supportEmail,
            },
            {
              field: "wanInfo.secondary.staticIP",
              headerName: "Secondary Static IP",
              headerClass: "tableHeader",
              hide: true,
              valueGetter: ({ data }) =>
                data.wanInfo?.find((x: WANInfo) => x.type === "Secondary")
                  ?.staticIP,
            },
            {
              field: "wanInfo.secondary.subnet",
              headerName: "Secondary Subnet",
              headerClass: "tableHeader",
              hide: true,
              valueGetter: ({ data }) =>
                data.wanInfo?.find((x: WANInfo) => x.type === "Secondary")
                  ?.subnet,
            },
            {
              field: "wanInfo.secondary.gateway",
              headerName: "Secondary Gateway",
              headerClass: "tableHeader",
              hide: true,
              valueGetter: ({ data }) =>
                data.wanInfo?.find((x: WANInfo) => x.type === "Secondary")
                  ?.gateway,
            },
          ],
        ],
        [
          appRoles.StoreInfo.ViewBuilding,
          [
            {
              field: "building.squareFeet",
              headerName: "Square Feet",
              headerClass: "tableHeader",
              hide: true,
            },
            {
              field: "building.generation",
              headerName: "Generation",
              headerClass: "tableHeader",
              hide: true,
            },
            // Uncomment if needed
            // {
            //   field: "building.hasDriveThrough",
            //   headerName: "Has Drive Through",
            //   headerClass: "tableHeader",
            //   hide: true,
            // },
            {
              field: "cardFreeInfo.driveThroughPickup",
              headerName: "Pickup Window",
              headerClass: "tableHeader",
              hide: true,
              cellRenderer: ({ value }: ICellRendererParams) => (
                <Switch disabled checked={value} color="success" />
              ),
              filterValueGetter: ({ data }: { data: StoreAggregate }) =>
                !!data.cardFreeInfo?.driveThroughPickup,
            },
          ],
        ],
        [
          appRoles.StoreInfo.ViewPeople,
          [
            {
              field: "managingPartnerName",
              headerName: "Managing Partner Name",
              headerClass: "tableHeader",
              valueGetter: ({ data }) => {
                const managingPartner: ManagerInfo = data?.managers?.find(
                  (x: ManagerInfo) => x.jobCodeNumber === "2740"
                );
                return `${managingPartner?.firstName ?? ""} ${
                  managingPartner?.lastName ?? ""
                }`;
              },
            },
            {
              field: "managingPartnerEmail",
              headerName: "Managing Partner Email",
              headerClass: "tableHeader",
              hide: true,
              valueGetter: ({ data }) => {
                const managingPartner: ManagerInfo = data?.managers?.find(
                  (x: ManagerInfo) => x.jobCodeNumber === "2740"
                );
                return managingPartner?.email;
              },
            },
            {
              field: "managingPartnerEmployeeNumber",
              headerName: "Managing Partner Employee Number",
              headerClass: "tableHeader",
              hide: true,
              valueGetter: ({ data }) => {
                const managingPartner: ManagerInfo = data?.managers?.find(
                  (x: ManagerInfo) => x.jobCodeNumber === "2740"
                );
                return managingPartner?.employeeNumber;
              },
            },
            {
              field: "managingPartnerPhone",
              headerName: "Managing Partner Phone Number",
              headerClass: "tableHeader",
              hide: true,
              valueGetter: ({ data }) => {
                const managingPartner: ManagerInfo = data?.managers?.find(
                  (x: ManagerInfo) => x.jobCodeNumber === "2740"
                );
                return managingPartner?.phoneNumber;
              },
            },
          ],
        ],
        [
          appRoles.StoreInfo.CardFreeHOO,
          [
            {
              field: "hoursOfOperation.timeZone",
              headerName: "CF HOO Timezone",
              headerClass: "tableHeader",
              hide: true,
            },
            {
              field: "hoursOfOperation.dailyOperatingSchedules.monday.open",
              headerName: "CF HOO Monday Open",
              headerClass: "tableHeader",
              hide: true,
            },
            {
              field: "hoursOfOperation.dailyOperatingSchedules.monday.close",
              headerName: "CF HOO Monday Close",
              headerClass: "tableHeader",
              hide: true,
            },
            {
              field: "hoursOfOperation.dailyOperatingSchedules.tuesday.open",
              headerName: "CF HOO Tuesday Open",
              headerClass: "tableHeader",
              hide: true,
            },
            {
              field: "hoursOfOperation.dailyOperatingSchedules.tuesday.close",
              headerName: "CF HOO Tuesday Close",
              headerClass: "tableHeader",
              hide: true,
            },
            {
              field: "hoursOfOperation.dailyOperatingSchedules.wednesday.open",
              headerName: "CF HOO Wednesday Open",
              headerClass: "tableHeader",
              hide: true,
            },
            {
              field: "hoursOfOperation.dailyOperatingSchedules.wednesday.close",
              headerName: "CF HOO Wednesday Close",
              headerClass: "tableHeader",
              hide: true,
            },
            {
              field: "hoursOfOperation.dailyOperatingSchedules.thursday.open",
              headerName: "CF HOO Thursday Open",
              headerClass: "tableHeader",
              hide: true,
            },
            {
              field: "hoursOfOperation.dailyOperatingSchedules.thursday.close",
              headerName: "CF HOO Thursday Close",
              headerClass: "tableHeader",
              hide: true,
            },
            {
              field: "hoursOfOperation.dailyOperatingSchedules.friday.open",
              headerName: "CF HOO Friday Open",
              headerClass: "tableHeader",
              hide: true,
            },
            {
              field: "hoursOfOperation.dailyOperatingSchedules.friday.close",
              headerName: "CF HOO Friday Close",
              headerClass: "tableHeader",
              hide: true,
            },
            {
              field: "hoursOfOperation.dailyOperatingSchedules.saturday.open",
              headerName: "CF HOO Saturday Open",
              headerClass: "tableHeader",
              hide: true,
            },
            {
              field: "hoursOfOperation.dailyOperatingSchedules.saturday.close",
              headerName: "CF HOO Saturday Close",
              headerClass: "tableHeader",
              hide: true,
            },
            {
              field: "hoursOfOperation.dailyOperatingSchedules.sunday.open",
              headerName: "CF HOO Sunday Open",
              headerClass: "tableHeader",
              hide: true,
            },
            {
              field: "hoursOfOperation.dailyOperatingSchedules.sunday.close",
              headerName: "CF HOO Sunday Close",
              headerClass: "tableHeader",
              hide: true,
            },
          ],
        ],
        [
          appRoles.Home.ViewPOSStatus,
          [
            {
              field: "devices_flat.CASHIER1",
              headerName: "CASHIER1",
              headerClass: "tableHeader",
              hide: true,
              cellRenderer: ({ value }: ICellRendererParams) => (
                <Switch disabled checked={value} color="success" />
              ),
              filterValueGetter: ({ data }: { data: StoreAggregate }) =>
                !!data.devices_flat.CASHIER1,
            },
            {
              field: "devices_flat.CASHIER2",
              headerName: "CASHIER2",
              headerClass: "tableHeader",
              hide: true,
              cellRenderer: ({ value }: ICellRendererParams) => (
                <Switch disabled checked={value} color="success" />
              ),
              filterValueGetter: ({ data }: { data: StoreAggregate }) =>
                !!data.devices_flat.CASHIER2,
            },
            {
              field: "devices_flat.CASHIER3",
              headerName: "CASHIER3",
              headerClass: "tableHeader",
              hide: true,
              cellRenderer: ({ value }: ICellRendererParams) => (
                <Switch disabled checked={value} color="success" />
              ),
              filterValueGetter: ({ data }: { data: StoreAggregate }) =>
                !!data.devices_flat.CASHIER3,
            },
            {
              field: "devices_flat.BAR1",
              headerName: "BAR1",
              headerClass: "tableHeader",
              hide: true,
              cellRenderer: ({ value }: ICellRendererParams) => (
                <Switch disabled checked={value} color="success" />
              ),
              filterValueGetter: ({ data }: { data: StoreAggregate }) =>
                !!data.devices_flat.BAR1,
            },
            {
              field: "devices_flat.BAR2",
              headerName: "BAR2",
              headerClass: "tableHeader",
              hide: true,
              cellRenderer: ({ value }: ICellRendererParams) => (
                <Switch disabled checked={value} color="success" />
              ),
              filterValueGetter: ({ data }: { data: StoreAggregate }) =>
                !!data.devices_flat.BAR2,
            },
            {
              field: "devices_flat.DRIVETHRU1",
              headerName: "DRIVETHRU1",
              headerClass: "tableHeader",
              hide: true,
              cellRenderer: ({ value }: ICellRendererParams) => (
                <Switch disabled checked={value} color="success" />
              ),
              filterValueGetter: ({ data }: { data: StoreAggregate }) =>
                !!data.devices_flat.DRIVETHRU1,
            },
            {
              field: "devices_flat.TAKEOUT1",
              headerName: "TAKEOUT1",
              headerClass: "tableHeader",
              hide: true,
              cellRenderer: ({ value }: ICellRendererParams) => (
                <Switch disabled checked={value} color="success" />
              ),
              filterValueGetter: ({ data }: { data: StoreAggregate }) =>
                !!data.devices_flat.TAKEOUT1,
            },
            {
              field: "devices_flat.TAKEOUT2",
              headerName: "TAKEOUT2",
              headerClass: "tableHeader",
              hide: true,
              cellRenderer: ({ value }: ICellRendererParams) => (
                <Switch disabled checked={value} color="success" />
              ),
              filterValueGetter: ({ data }: { data: StoreAggregate }) =>
                !!data.devices_flat.TAKEOUT2,
            },
          ],
        ],
      ]);
      setColDefs(() =>
        Array.from(rolesGridColsMap)
          .filter(([key]) => roles.includes(key))
          .flatMap(([, value]) => value)
      );
    }
  }, [storesInfo, roles]);

  const handleExportAsCsvButtonClick = () => {
    gridRef.current?.api.exportDataAsCsv({
      fileName: `export_${dayjs(dayjs())
        .format("YYYY_MM_DD_HH_mm_ss")
        .toString()}`,
    });
    // gridRef.current?.api.getDataAsCsv();
  };

  const handleColumnsMenuChange = (e: ChangeEvent<HTMLInputElement>) => {
    setColDefs((prev) =>
      prev.map((col: ColDef) =>
        col.field === e.target.name ? { ...col, hide: !col.hide } : col
      )
    );
  };

  const onInitialDataRender = async () => {
    gridRef.current?.api.sizeColumnsToFit();

    await gridRef.current?.api.setColumnFilterModel("basicInfo.status", {
      filter: "Opened",
      type: "equals",
    });
    gridRef.current?.api.onFilterChanged();
  };

  return (
    <LoadingIndicator show={!colDefs.length}>
      <div id="grid-container" className={"ag-theme-quartz"}>
        <Box sx={{ mb: 1 }}>
          <Button
            startIcon={<ViewWeekIcon />}
            onClick={(e) => setMenuAnchorElement(e.currentTarget)}
          >
            Columns
          </Button>
          <Button
            sx={{
              ml: 1,
              minWidth: "max-content",
            }}
            startIcon={<DownloadIcon />}
            onClick={handleExportAsCsvButtonClick}
          >
            Export as CSV
          </Button>
          <Menu
            open={Boolean(menuAnchorElement)}
            anchorEl={menuAnchorElement}
            onClose={() => setMenuAnchorElement(null)}
          >
            {colDefs.map((col: ColDef) => (
              <MenuItem key={col.field}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!col.hide}
                      name={col.field}
                      onChange={handleColumnsMenuChange}
                    />
                  }
                  label={col.headerName}
                />
              </MenuItem>
            ))}
          </Menu>
        </Box>
        <AgGridReact
          loading={!colDefs.length}
          getRowId={(params) => params?.data?.basicInfo?.number}
          defaultColDef={{
            filter: "agTextColumnFilter",
            filterParams: { buttons: ["apply", "clear"], closeOnApply: true },
          }}
          pagination
          paginationPageSize={50}
          rowData={storesInfo}
          columnDefs={colDefs}
          ref={gridRef}
          onGridReady={() => gridRef.current?.api.sizeColumnsToFit()}
          onFirstDataRendered={onInitialDataRender}
        />
      </div>
    </LoadingIndicator>
  );
};
