import { ReactElement, useEffect, useState } from "react";
import "./SecondRow.css";
import { Box } from "@mui/material";
import { appRoles, config } from "../../authConfig";
import {
    StoreWANWidget,
    TableInfo,
} from "../../Common/UI/components/StoreWANWidget";
import { FlexRow } from "../../Common/UI/components/FlexRow";
import useIsAuthorized from "../../Common/UI/hook/useIsAuthorized";
import IntegrationStatuses from "../../Common/UI/components/IntegrationStatuses";
import { CardFreeInfo } from "./CardFreeInfo";
import { WANStatuses } from "./WANStatuses";
import { POSStatus } from "./POSStatus";

function WANStatusesConvert(
    WANStatuses: WANStatuses[]
): TableInfo[] {
    var temp: TableInfo[] = [];

    WANStatuses.forEach((info) => {
        temp.push({
            location: info.locationWanInfo.location,
            col2: info.wan1Status,
            col2Name: "WAN 1",
            col1: info.wan2Status,
            col1Name: "WAN 2",
            status: info.status,
            key: "wan-info",
            updateDate: info.lastUpdated
        });
    });
    return temp;
}

function CardFreeStatusesConvert(cardFreeInfo: CardFreeInfo[]): TableInfo[] {
    var temp: TableInfo[] = [];

    cardFreeInfo.forEach((info) => {
        temp.push({
            location: info.location, //location number
            col1Name: "Status", //most right col name
            col1: info.status, // most right col status
            status: info.status,
            key: "card-free-info",
            updateDate: info.lastUpdated
        });
    });
    return temp;
}

function POSStatusConvert(posStatuses: POSStatus[]): TableInfo[] {
    var temp: TableInfo[] = [];

    posStatuses.forEach((info) => {
        temp.push({
            location: info.location, //location number
            col1Name: "Status", 
            col1: info.activeDevices > 0, 
            status:  info.activeDevices > 0,
            key: "pos-statuses",
            updateDate: info.lastUpdated
        });
    });
    return temp;
}

export const SecondRow = function ({
    loadingWANStatuses,
    WANStatuses,
    loadingCardFreeStatuses,
    cardFreeStatuses,
    loadingPOSStatuses,
    POSStatuses,
}: {
    loadingWANStatuses: boolean;
    WANStatuses: WANStatuses[];
    loadingCardFreeStatuses: boolean;
    cardFreeStatuses: CardFreeInfo[];
    loadingPOSStatuses: boolean;
    POSStatuses: POSStatus[];
}): ReactElement {

    const showFirewallStatus = useIsAuthorized([appRoles.Home.FirewallStatus]);
    const showCardFree = useIsAuthorized([appRoles.Home.CardFreeStatus]);
    const showIntegration = useIsAuthorized([appRoles.Integration.ViewStatus]);
    const showPOS = useIsAuthorized([appRoles.Home.ViewPOSStatus]);

    const [wanInfo, setWanInfo] = useState<TableInfo[]>([]);
    const [CFInfo, setCFInfo] = useState<TableInfo[]>([]);
    const [POSInfo, setPOSInfo] = useState<TableInfo[]>([]);

    useEffect(() => {
        setWanInfo(WANStatusesConvert(WANStatuses));
    }, [WANStatuses]);

    useEffect(() => {
        setCFInfo(CardFreeStatusesConvert(cardFreeStatuses));
    }, [cardFreeStatuses]);

    useEffect(() => {
        setPOSInfo(POSStatusConvert(POSStatuses));
    }, [POSStatuses]);

    return (
        <FlexRow>
            {showFirewallStatus && (
                <StoreWANWidget
                    items={wanInfo}
                    isLoading={loadingWANStatuses}
                    title="Network Store Status"
                    header="Store Number"
                ></StoreWANWidget>
            )}
            {showCardFree && (
                <StoreWANWidget
                    items={CFInfo}
                    isLoading={loadingCardFreeStatuses}
                    title="CardFree Store Status"
                    header="Store Name"
                ></StoreWANWidget>
            )}

            {showPOS && (
                <StoreWANWidget
                    items={POSInfo}
                    isLoading={loadingPOSStatuses}
                    title="POS Terminals Status"
                    header="Store Number"
                ></StoreWANWidget>
            )}
            {showIntegration && (
                <Box>
                    <IntegrationStatuses showDateFilter={false}></IntegrationStatuses>
                </Box>
            )}
        </FlexRow>
    );
};
