import { Typography } from "@mui/material";
import { theme } from "../../../Common/UI/styles/theme";
import ReleaseOnePointSix from "./ReleaseOnePointSix";
import ReleaseOnePointSeven from "./ReleaseOnePointSeven";

export default function ReleasesHelp() {
  return (
    <>
      <ReleaseOnePointSeven />
      <ReleaseOnePointSix />
      <Typography
        variant="h6"
        className="release-title"
        color={theme.palette.error.main}
      >
        {" "}
        RELEASE 1.5 – September 10th , 2024
      </Typography>
      <ul>
        <li>
          Dashboard Home - <strong>NEW</strong> This release will introduce
          CardFree Online Status on the Dashboard Home. Use the two widgets on
          the Dashboard to quickly find which stores are Offline on CardFree.
        </li>
        <li>
          Dashboard Home - <strong>NEW</strong> Now you can easily share your
          ideas & enhancement requests for the Dashboard! Please use the Idea
          Form (Light Bulb Icon) next to the Help page to submit your requests –
          We look forward to hearing from you!
        </li>
        <li>
          Store 411 –
          <ul>
            <li style={{ listStyleType: "circle" }}>
              <strong>NEW</strong> CF HOO – Provides the list of Hours of
              Operations for Stores per day as it is setup in CardFree
            </li>
            <li style={{ listStyleType: "circle" }}>
              Network Tab - <strong>NEW</strong> With the correct permissions
              assigned to your User Role, you will now be able to edit Network
              IP addresses under the Network tab
            </li>
            <li style={{ listStyleType: "circle" }}>
              <strong>NEW</strong> Building Properties Tab -
              <ul>
                {/* <li style={{ listStyleType: "circle" }}>Drive Thru Flag Yes/No</li>*/}
                <li style={{ listStyleType: "circle" }}>
                  Pickup Window Flag Yes/No
                </li>
                <li style={{ listStyleType: "circle" }}>
                  Moved Store SQFT & Store Gen fields from Basic Info Tab
                </li>
              </ul>
            </li>
            <li style={{ listStyleType: "circle" }}>
              Basic Info Tab - <strong>NEW</strong> Store Email Address{" "}
            </li>
          </ul>
        </li>

        <li>
          Bug Fixes:
          <ul>
            <li style={{ listStyleType: "circle" }}>
              Sync Employee was returning an error message although sync was
              successful
            </li>
            <li style={{ listStyleType: "circle" }}>
              LRS Report, disable future dates
            </li>
            <li style={{ listStyleType: "circle" }}>
              Update Effective date error handling
            </li>
            <li style={{ listStyleType: "circle" }}>Cosmetic fixes</li>
          </ul>
        </li>
      </ul>

      <Typography
        variant="h6"
        className="release-title"
        color={theme.palette.error.main}
      >
        {" "}
        RELEASE 1.4 - August 8th, 2024
      </Typography>
      <ul>
        <li>
          Releasing new branding Diablo Dashboard, or DDash!!{" "}
          <strong>NEW URL - UPDATE your bookmarks!</strong>
        </li>
        <li>
          Dashboard Home - Store Network Status Widget & Detail, display stores
          with offline network at the top of the list - no need to scroll
          anymore!!
        </li>
        <li>
          Manage POS Emp - <strong>NEW</strong> Display Location Name, display
          actual error message when Sync process fails{" "}
        </li>
        <li>
          Store 411 -
          <ul>
            <li style={{ listStyleType: "circle" }}>
              <strong>NEW</strong> People Tab – List of MP, & Salaried managers,
              emails and MP phone number{" "}
            </li>
            <li style={{ listStyleType: "circle" }}>
              <strong>NEW</strong> Network tab – Find WAN IP Addresses per
              location{" "}
            </li>
          </ul>
        </li>
      </ul>
      <Typography
        variant="h6"
        className="release-title"
        color={theme.palette.error.main}
      >
        RELEASE 1.3 - JULY 11th, 2024
      </Typography>
      <ul>
        <li>
          Dashboard Home - <strong>NEW</strong> Store Network Status Widget &
          Detail
        </li>
        <li>
          Manage POS Emp - <strong>NEW</strong> Display Error Message After Sync
        </li>
        <li>
          <strong>NEW</strong> Reports - New LRS Report
        </li>
        <li>
          Store 411 - Basic Info Tab <strong>NEW</strong> Store Status, Store
          Direct Phone Number, Store Alternate Name
        </li>
      </ul>
    </>
  );
}
